import {
  ValidationRules,
  ValidationController,
  Validator
} from "aurelia-validation";
import { DeleteDialogService } from "services/delete-dialog-service";
import { I18N } from "aurelia-i18n";
import { DialogService } from "aurelia-dialog";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject, bindable } from "aurelia-framework";
import {
  Router} from "aurelia-router";
import { Prompt } from "elements/prompt";
import { Models } from "models/core";
import { ErrorService } from "services/error-service";
import { NetOfferService } from "services/net-offer-service";
import { NetService } from "services/net-service";
import { OfferAdditionalCostService } from "services/offer-additional-cost-service";
import { ProducerService } from "services/producer-service";
import { ToastService } from "services/toast-service";
import { Utility } from "../../utility";
import tippy from "tippy.js";
import * as moment from "moment";

@autoinject
export class SimpleOfferTabPricing {
  @bindable private locked: boolean = false;
  private offer: Models.NetOffer;
  private net: Models.Net;
  private netStandard2009 = Models.NetStandardId.NS9415_2009;
  private isNs9415Compliant: boolean;
  private is2009Standard: boolean;
  private formIsValid: boolean = true;
  private offerCurrencyId: number;

  private currentCostItem: any;
  private priceItemsNet: Array<any>;
  private priceItemsFreightAndAnalysis: Array<any>;
  private priceItemsAntifouling: Array<any>;

  private sumItemsNet: number;
  private sumItemsNetCost: number;
  private sumItemsNetMargin: number;

  private sumItemsTotal: number;
  private sumItemsTotalCost: number;
  private sumItemsTotalMargin: number;

  private sumItemsFreightAndAnalysis: number;
  private sumItemsFreightAndAnalysisCost: number;
  private sumItemsFreightAndAnalysisMargin: number;

  private sumItemsAntifouling: number;
  private sumItemsAntifoulingCost: number;
  private sumItemsAntifoulingMargin: number;

  private sumItemsNetAndFreight: number;
  private sumItemsNetAndFreightCost: number;
  private sumItemsNetAndFreightMargin: number;

  private freightAndAnalysisPricesDetailsVisible: boolean = true;
  private netPricesDetailsVisible: boolean = true;
  private antiFoulingDetailsVisible: boolean = false;

  private materials: Array<Models.Material>;
  private originalObject;
  private nextTabIndex: number = null;

  private priceTotalData: any;
  private historicPriceInfoText: string;

  private showMorenotPrices: boolean = true;
  private showDeltaPrices: boolean = false;

  private changeTabSubscription: Subscription;

  private showPriceEstimate = false;

  constructor(
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private netOfferService: NetOfferService,
    private producerService: ProducerService,
    private netService: NetService,
    private offerAdditionalCostService: OfferAdditionalCostService,
    private dialogService: DialogService,
    private deleteDialogService: DeleteDialogService,
    private validationController: ValidationController,
    private validator: Validator,
    private utility: Utility,
    private i18n: I18N
  ) {}

  private activate(params) {
    this.getNetOffer(params.Id);

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.changeTabSubscription = this.eventAggregator.subscribe("changeTab", tabIndex => {
      this.nextTabIndex = tabIndex;
    });
  }

  private detached() {
    if (this.changeTabSubscription) {
      this.changeTabSubscription.dispose();
    }
  }

  private deactivate() {
    if (this.changeTabSubscription) {
      this.changeTabSubscription.dispose();
    }
  }

  private getProducers(): Promise<any> {
    return this.producerService.getAllCached().then(res => {
      return res.filter(x => x.IsMorenotCompany);
    });
  }

  private validateBeforeUpdate() {
    // MANUAL VALIDATION
    this.validator.validateObject(this.offer).then(result => {
      const errors = result.filter(validateResult => {
        return !validateResult.valid;
      });
      if (errors.length > 0) {
        this.validationController.validate();
      } else {
        this.validationController.validate();
        this.updateOffer();
      }
    });
  }

  private updateOffer() {
    const net = this.offer.Net;
    delete net.NetDimension;

    this.netService
      .put(net, net.Id)
      .then(resNet => {
        this.offer.Net = null;

        let didChangeApprovedQualityCheck = false;

        if (
          this.offer["_approvedQualityCheck"] &&
          !this.offer.ApprovedQualityCheck
        ) {
          this.offer.ApprovedQualityCheck = new Date();
          didChangeApprovedQualityCheck = true;
        } else if (
          !this.offer["_approvedQualityCheck"] &&
          this.offer.ApprovedQualityCheck
        ) {
          this.offer.ApprovedQualityCheck = null;
          didChangeApprovedQualityCheck = true;
        }

        delete this.offer["_approvedQualityCheck"];

        this.netOfferService
          .put(this.offer, this.offer.Id)
          .then(resOffer => {
            this.getNetOffer(this.offer.Id).then(res => {
              this.eventAggregator.publish("offer-refresh", "price");
              this.toastService.showSuccess("offer.updated");

              if (didChangeApprovedQualityCheck) {
                this.eventAggregator.publish("offer-refresh", "offer");
              }
            });
          })
          .catch(err => this.errorService.handleError(err));
      })
      .catch(err => this.errorService.handleError(err));
  }

  // Get netoffer
  private getNetOffer(id): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.netOfferService
        .get(id + "?$expand=Net")
        .then(res => {
          this.offer = res;
          this.offerCurrencyId = this.offer.CurrencyId;
          this.showMorenotPrices = this.offer.PricePlace === 1;
          this.showDeltaPrices = this.offer.PricePlace === 2;
          this.offer["_approvedQualityCheck"] = !!this.offer
            .ApprovedQualityCheck;

          this.locked = res.Net.Locked ? res.Net.Locked : false;

          ValidationRules.ensure("AntifoulingAddMarginPerLiter")
            .required()
            .withMessage(this.i18n.tr("general.requiredField"))
            .ensure("AntifoulingApplicationCost")
            .required()
            .withMessage(this.i18n.tr("general.requiredField"))
            .on(this.offer);

          this.getNet(this.offer.NetId);

          this.getPriceCalculationItems().then(res => {
            resolve();
          });

          this.updateOriginalObject();
        })
        .catch(err => this.errorService.handleError(err));
    });
  }

  private checkNs9415Compliant () {
    this.isNs9415Compliant = this.offer.Net.NetDimension.Ns9415Compliant ? true : false;
  }

  private getNet(id) {
    this.netService
      .get(id + "?$expand=NetDimension($expand=NetType)")
      .then(res => {
        this.offer.Net = res;
        this.updateOriginalObject();

        this.checkNs9415Compliant();
        this.is2009Standard = this.offer.Net.NetDimension.NetStandardId === this.netStandard2009;
      })
      .catch(err => this.errorService.handleError(err));
  }

  private deleteCostItem(item) {
    this.deleteDialogService.confirmBeforeDelete(() => {
      this.offerAdditionalCostService
        .delete(this.currentCostItem.Id)
        .then(res => {
          this.currentCostItem = null;
          this.getPriceCalculationItems();
        })
        .catch(err => this.errorService.handleError(err));
    });
  }

  private addCostItem(costGroup: number) {
    this.currentCostItem = {
      Id: 0,
      CostGroup: costGroup,
      Specification: "",
      SpecificationTranslated: "",
      Total: 0,
      TotalDelta: 0,
      NetOfferId: this.offer.Id,
      EditNameDisabled: false,
      CurrencyId: this.offer.CurrencyId
    };
  }

  private editCostItem(item) {
    this.currentCostItem = {
      Id: item.OfferAdditionalCostId,
      Specification: item.Specification,
      SpecificationTranslated: item.SpecificationTranslated,
      Total: item.CostPrice,
      TotalDelta: item.CostPriceDelta,
      NetOfferId: this.offer.Id,
      EditNameDisabled:
        item.Specification != item.SpecificationTranslated &&
        item.SpecificationTranslated,
      CurrencyId: item.CurrencyId
    };
  }

  private saveCostItem() {
    delete this.currentCostItem.EditNameDisabled;
    delete this.currentCostItem.SpecificationTranslated;

    if (this.currentCostItem.Id) {
      this.offerAdditionalCostService
        .put(this.currentCostItem as any, this.currentCostItem.Id)
        .then(res => {
          this.getPriceCalculationItems();
          this.currentCostItem = null;
        })
        .catch(err => this.errorService.handleError(err));
    } else {
      this.offerAdditionalCostService
        .post(this.currentCostItem as any)
        .then(res => {
          this.getPriceCalculationItems();
          this.currentCostItem = null;
        })
        .catch(err => this.errorService.handleError(err));
    }
  }

  private cancelEdit() {
    this.currentCostItem = null;
  }

  private cancel() {
    this.getNetOffer(this.offer.Id);
  }

  // calculate prices
  private getPriceCalculationItems() {
    return this.netOfferService
      .getPriceCalculationItems(this.offer.Id)
      .then(res => {
        this.priceTotalData = res;

        if (this.priceTotalData.IsHistoricPrices) {
          let formattedDate = moment
            .utc(this.priceTotalData.PricesCalculated)
            .local()
            .format("DD.MM.YYYY HH:mm:ss");
          this.historicPriceInfoText = this.i18n.tr("offer.historicpriceinfo", {
            formattedDate: formattedDate
          });
        } else {
          this.historicPriceInfoText = null;
        }

        let items = res.Items;
        items.forEach(x => {
          if (
            x.CostType === "AdditionalCost" &&
            (x.Specification === "Freight" ||
              x.Specification === "Mounting" ||
              x.Specification === "ExtraProductsPrice" ||
              x.Specification === "Drawing" ||
              x.Specification === "ClassZeroCalculations")
          ) {
            x.SpecificationTranslated = this.i18n.tr(
              "pricing." + x.Specification
            );
          }

          x.CostTypeTranslated = this.i18n.tr("pricing." + x.CostType);
        });

        this.priceItemsNet = items.filter(x => x.CostGroup === 1);
        this.priceItemsFreightAndAnalysis = items.filter(
          x => x.CostGroup === 2
        );
        this.priceItemsAntifouling = items.filter(x => x.CostGroup === 3);

        this.calculateSums();

        setTimeout(() => {
          tippy(".cost-item", {
            allowHTML: true,
            maxWidth: "1100px",
            trigger: "click",
            hideOnClick: true,
            content: reference => {
              const title = reference.getAttribute("title");
              reference.removeAttribute("title");
              return title;
            }
          });
        });
      })
      .catch(err => this.errorService.handleError(err));
  }

  private exportBillOfMaterial() {
    if (
      this.originalObject &&
      !this.utility.areEqual(this.offer, this.originalObject)
    ) {
      this.toastService.showError("netoffer.pricedetailexportsavechangesfirst");
      return;
    }

    this.netOfferService
      .getBlob("export-bill-of-material/" + this.offer.Id)
      .then(res => {
        this.toastService.showSuccess("netoffer.billOfMaterialExported");
      })
      .catch(err => this.errorService.handleError(err));
  }

  private exportPriceDetails() {
    if (
      this.originalObject &&
      !this.utility.areEqual(this.offer, this.originalObject)
    ) {
      this.toastService.showError("netoffer.pricedetailexportsavechangesfirst");
      return;
    }

    this.netOfferService
      .getBlob("export-price-calculation-items/" + this.offer.Id)
      .then(res => {
        this.toastService.showSuccess("netoffer.pricedetailsexported");
      })
      .catch(err => this.errorService.handleError(err));
  }

  private calculateSums() {
    this.sumItemsNet = 0;
    this.sumItemsNetCost = 0;
    this.sumItemsFreightAndAnalysis = 0;
    this.sumItemsFreightAndAnalysisCost = 0;
    this.sumItemsAntifouling = 0;
    this.sumItemsAntifoulingCost = 0;

    this.priceItemsNet.forEach(x => {
      if (this.showMorenotPrices) {
        this.sumItemsNet +=
          x.SalesPrice !== 0 ? x.SalesPrice : x.SalesPriceDelta;
        this.sumItemsNetCost +=
          x.CostPrice !== 0 ? x.CostPrice : x.CostPriceDelta;
      } else {
        this.sumItemsNet +=
          x.SalesPriceDelta !== 0 ? x.SalesPriceDelta : x.SalesPrice;
        this.sumItemsNetCost +=
          x.CostPriceDelta !== 0 ? x.CostPriceDelta : x.CostPrice;
      }
    });

    this.sumItemsNetMargin =
      (100 * (this.sumItemsNet - this.sumItemsNetCost)) / this.sumItemsNet;

    this.priceItemsFreightAndAnalysis.forEach(x => {
      if (this.showMorenotPrices) {
        this.sumItemsFreightAndAnalysis +=
          x.SalesPrice !== 0 ? x.SalesPrice : x.SalesPriceDelta;
        this.sumItemsFreightAndAnalysisCost +=
          x.CostPrice !== 0 ? x.CostPrice : x.CostPriceDelta;
      } else {
        this.sumItemsFreightAndAnalysis +=
          x.SalesPriceDelta !== 0 ? x.SalesPriceDelta : x.SalesPrice;
        this.sumItemsFreightAndAnalysisCost +=
          x.CostPriceDelta !== 0 ? x.CostPriceDelta : x.CostPrice;
      }
    });

    this.sumItemsFreightAndAnalysisMargin =
      (100 *
        (this.sumItemsFreightAndAnalysis -
          this.sumItemsFreightAndAnalysisCost)) /
      this.sumItemsFreightAndAnalysis;

    this.sumItemsNetAndFreight =
      this.sumItemsNet + this.sumItemsFreightAndAnalysis;
    this.sumItemsNetAndFreightCost =
      this.sumItemsNetCost + this.sumItemsFreightAndAnalysisCost;
    this.sumItemsNetAndFreightMargin =
      (100 * (this.sumItemsNetAndFreight - this.sumItemsNetAndFreightCost)) /
      this.sumItemsNetAndFreight;

    this.priceItemsAntifouling.forEach(x => {
      if (this.showMorenotPrices) {
        this.sumItemsAntifouling +=
          x.SalesPrice !== 0 ? x.SalesPrice : x.SalesPriceDelta;
        this.sumItemsAntifoulingCost +=
          x.CostPrice !== 0 ? x.CostPrice : x.CostPriceDelta;
      } else {
        this.sumItemsAntifouling +=
          x.SalesPriceDelta !== 0 ? x.SalesPriceDelta : x.SalesPrice;
        this.sumItemsAntifoulingCost +=
          x.CostPriceDelta !== 0 ? x.CostPriceDelta : x.CostPrice;
      }
    });

    this.sumItemsAntifoulingMargin =
      (100 * (this.sumItemsAntifouling - this.sumItemsAntifoulingCost)) /
      this.sumItemsAntifouling;

    this.sumItemsTotal =
      this.sumItemsNet +
      this.sumItemsFreightAndAnalysis +
      this.sumItemsAntifouling;
    this.sumItemsTotalCost =
      this.sumItemsNetCost +
      this.sumItemsFreightAndAnalysisCost +
      this.sumItemsAntifoulingCost;
    this.sumItemsTotalMargin =
      (100 * (this.sumItemsTotal - this.sumItemsTotalCost)) /
      this.sumItemsTotal;
  }

  private setShowMorenotPrices() {
    this.showDeltaPrices = false;
    this.showMorenotPrices = true;
    this.offer.PricePlace = 1;
    this.calculateSums();
    return true;
  }

  private setShowDeltaPrices() {
    this.showDeltaPrices = true;
    this.showMorenotPrices = false;
    this.offer.PricePlace = 2;
    this.calculateSums();
    return true;
  }

  private updateOriginalObject() {
    if (!this.offer) {
      return;
    }
    this.originalObject = JSON.parse(JSON.stringify(this.offer));
  }

  private addValidationRules() {
    /*     ValidationRules.ensure("NetTypeId")
      .required()
      .withMessage(this.i18n.tr("general.requiredField"))
      .on(this.net); */
  }

  private validateForm() {
    this.validationController.validate().then(result => {
      if (result.valid) {
        this.formIsValid = true;
      } else {
        this.formIsValid = false;
      }
    });
  }

  private async validateAsync(): Promise<any> {
    return new Promise<void>(async (resolve, reject) => {
      this.validationController
        .validate()
        .then(result => {
          if (result.valid) {
            this.formIsValid = true;
            resolve();
          } else {
            this.formIsValid = false;
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  private async save(): Promise<any> {
    return new Promise<void>(async (resolve, reject) => {
      const net = this.offer.Net;
      delete net.NetDimension;

      this.netService
        .put(net, net.Id)
        .then(resNet => {
          this.offer.Net = null;

          let didChangeApprovedQualityCheck = false;

          if (
            this.offer["_approvedQualityCheck"] &&
            !this.offer.ApprovedQualityCheck
          ) {
            this.offer.ApprovedQualityCheck = new Date();
            didChangeApprovedQualityCheck = true;
          } else if (
            !this.offer["_approvedQualityCheck"] &&
            this.offer.ApprovedQualityCheck
          ) {
            this.offer.ApprovedQualityCheck = null;
            didChangeApprovedQualityCheck = true;
          }

          delete this.offer["_approvedQualityCheck"];

          this.netOfferService
            .put(this.offer, this.offer.Id)
            .then(resOffer => {
              this.getNetOffer(this.offer.Id).then(res => {
                this.eventAggregator.publish("offer-refresh", "price");
                this.toastService.showSuccess("offer.updated");

                if (didChangeApprovedQualityCheck) {
                  this.eventAggregator.publish("offer-refresh", "offer");
                }
              });
              resolve();
            })
            .catch(err => this.errorService.handleError(err));
        })
        .catch(err => this.errorService.handleError(err));
    });
  }

  private changeTab(direction: string = "next") {
    if (direction === "prev") {
      this.eventAggregator.publish("triggerPrevTab", true);
    } else {
      this.eventAggregator.publish("triggerNextTab", true);
    }
  }

  private saveAndChangeTab(direction: string = "next") {
    // does it validate ?
    this.validateAsync()
      .then(() => {
        // check if different
        if (!this.utility.areEqual(this.offer, this.originalObject, true)) {
          // save
          this.save().then(res => {
            this.changeTab(direction);
          });
        } else {
          this.changeTab(direction);
        }
      })
      .catch(error => {
        this.toastService.showWarning("general.checkValidationErrors");
      });
  }

  private canDeactivate() {
    if (
      this.originalObject &&
      !this.utility.areEqual(this.offer, this.originalObject)
    ) {
      // tslint:disable-next-line:no-console

      return this.dialogService
        .open({
          viewModel: Prompt,
          model: {
            header: "dialog.pleaseConfirmHeader",
            message: "dialog.unsavedChangesText"
          }
        })
        .whenClosed(response => {
          if (response.wasCancelled) {
            return false;
          } else {
            const result = response.output;
            if (result === "save") {
              // save the offer and let that function handle the rest of the logic
              this.validateBeforeUpdate();
              return false;
            } else {
              this.eventAggregator.publish(
                "changeTab-success",
                this.nextTabIndex
              );
              return true;
            }
          }
        });
    } else {
      this.eventAggregator.publish("changeTab-success", this.nextTabIndex);
      return true;
    }
  }
}
