import { NetOfferService } from 'services/net-offer-service';
import { DialogController, DialogService } from "aurelia-dialog";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject, bindable } from "aurelia-framework";
import { Router } from "aurelia-router";
import { Prompt } from "elements/prompt";
import { Models } from "models/core";
import { EquipmentService } from "services/equipment-service";
import { ErrorService } from "services/error-service";
import { MarkingService } from "services/marking-service";
import { NetService } from "services/net-service";
import { ToastService } from "services/toast-service";
import { Utility } from "../../utility";

@autoinject
export class SimpleOfferTabMarking {
  private offer: Models.NetOffer;
  private originalObject;

  private markings: Array<Models.Marking>;
  private reinforcements: Array<Models.Reinforcement>;
  private equipments: Array<Models.Equipment>;

  private markingNewFormVisible: boolean = false;
  private markingEditFormVisible: number = null;
  private equipmentNewFormVisible: boolean = false;
  private equipmentEditFormVisible: number = null;

  private changeTabSubscription: Subscription;
  private subscription1: Subscription;
  private subscription2: Subscription;
  private subscription3: Subscription;
  private subscription4: Subscription;
  private subscription5: Subscription;
  private subscription6: Subscription;
  private subscription7: Subscription;
  private subscription8: Subscription;
  private nextTabIndex: number = null;
  private formIsValid: boolean = true;
  private locked: boolean = false;

  constructor(
    private equipmentService: EquipmentService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private dialogService: DialogService,
    private utility: Utility,
    private netService: NetService,
    private netOfferService: NetOfferService,
    private markingService: MarkingService
  ) {}

  private activate(params) {   
    this.getNetOffer(params.Id)
    
    this.subscription1 = this.eventAggregator.subscribe(
      "markingListReset",
      value => {
        this.markingEditFormVisible = null;
        this.getMarkings();
        this.eventAggregator.publish("offer-refresh", "price");
      }
    );

    this.subscription2 = this.eventAggregator.subscribe(
      "markingFormEditClose",
      value => {
        this.getMarkings();
      }
    );

    this.subscription3 = this.eventAggregator.subscribe(
      "markingFormNewClose",
      value => {
        this.markingNewFormVisible = false;
      }
    );

    this.subscription6 = this.eventAggregator.subscribe(
      "equipmentListReset",
      value => {
        this.equipmentEditFormVisible = null;
        this.getEquipments();
        this.eventAggregator.publish("offer-refresh", "price");
      }
    );

    this.subscription7 = this.eventAggregator.subscribe(
      "equipmentFormEditClose",
      value => {
        this.getEquipments();
      }
    );

    this.eventAggregator.subscribe("equipmentFormNewClose", value => {
      this.equipmentNewFormVisible = false;
    });

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.changeTabSubscription = this.eventAggregator.subscribe("changeTab", tabIndex => {
      this.nextTabIndex = tabIndex;
    });

    // refresh if locked / unlocked
    this.subscription8 = this.eventAggregator.subscribe("netLocked", value => {
      this.getNetOffer(this.offer.Id);
    });
  }

  private detached() {
    if (this.changeTabSubscription) {
      this.changeTabSubscription.dispose();
    }
  }

  private deactivate() {
    if (this.changeTabSubscription) {
      this.changeTabSubscription.dispose();
    }
    if (this.subscription1) {
      this.subscription1.dispose();
    }
    if (this.subscription2) {
      this.subscription2.dispose();
    }
    if (this.subscription3) {
      this.subscription3.dispose();
    }
    if (this.subscription4) {
      this.subscription4.dispose();
    }
    if (this.subscription5) {
      this.subscription5.dispose();
    }
    if (this.subscription6) {
      this.subscription6.dispose();
    }
    if (this.subscription7) {
      this.subscription7.dispose();
    }
    if (this.subscription8) {
      this.subscription8.dispose();
    }
  }

  private getNetOffer(id): Promise<any> {
    return this.netOfferService
      .get(id + "?$expand=Net")
      .then(res => {
        this.offer = res;
        this.getMarkings();
        this.getEquipments();
        this.locked = res.Net.Locked ? res.Net.Locked : false;

        this.updateOriginalObject();
      })
      .catch(err => this.errorService.handleError(err));
  }

  private updateOriginalObject() {
    if (!this.offer) {
      return;
    }
    this.originalObject = JSON.parse(JSON.stringify(this.offer));
  }

  private getMarkings() {
    this.markingService
      .getAll(
        "?$filter=NetId eq " +
          this.offer.NetId +
          "&$expand=MarkingColor,MarkingPlacement,MarkingType"
      )
      .then(res => {
        this.markings = res;
      })
      .catch(err => this.errorService.handleError(err));
  }

  private deleteMarking(id) {
    this.markingService
      .delete(id)
      .then(res => {
        this.toastService.showSuccess("marking.deleted");
        this.getMarkings();
      })
      .catch(err => this.errorService.handleError(err));
  }

  private deleteEquipment(id) {
    this.equipmentService
      .delete(id)
      .then(res => {
        this.toastService.showSuccess("equipment.deleted");
        this.getEquipments();
      })
      .catch(err => this.errorService.handleError(err));
  }

  private getEquipments() {
    this.equipmentService
      .getAll("?$filter=IsCenterBottomEquipment eq false and NetId eq " + this.offer.NetId + "&$expand=EquipmentType")
      .then(res => {
        this.equipments = res;
      })
      .catch(err => this.errorService.handleError(err));
  }

  private addValidationRules() {
    /*     ValidationRules.ensure("NetTypeId")
      .required()
      .withMessage(this.i18n.tr("general.requiredField"))
      .on(this.net); */
  }

  private validateForm() {
    return true;
  }

  private async validateAsync(): Promise<any> {
    return new Promise<void>(async (resolve, reject) => {
      resolve();
    });
  }

  private changeTab(direction: string = 'next'){
    if (direction === "prev") {
      this.eventAggregator.publish("triggerPrevTab", true);
    } else {
      this.eventAggregator.publish("triggerNextTab", true);
    }
  }

  private saveAndChangeTab(direction: string = "next") {
    // does it validate ?
    this.validateAsync()
      .then(() => {
        if (this.offer.CommentForCustomer == this.originalObject.CommentForCustomer 
          && this.offer.Net.Comment == this.originalObject.Net.Comment) {
          // if the comments didnt change, we dont need to update anything
          this.changeTab(direction);              
          return;
        }

        const net = this.offer.Net;
        delete net.NetDimension;

        // check if different
        this.netService
        .put(net, net.Id)
        .then(resNet => {
          this.offer.Net = null;

          this.netOfferService
            .put(this.offer, this.offer.Id)
            .then(resOffer => {
              this.getNetOffer(this.offer.Id).then(res => {
                this.changeTab(direction);              
                this.toastService.showSuccess("offer.updated");
              });              
            })
            .catch(err => this.errorService.handleError(err));
        })
        .catch(err => this.errorService.handleError(err));        
      })
      .catch(error => {
        this.toastService.showWarning("general.checkValidationErrors");
      });
  }

  private canDeactivate() {
    this.eventAggregator.publish("changeTab-success", this.nextTabIndex);
    return true;
  }
}
