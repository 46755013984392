import { NetDimensionService } from './../../services/net-dimension-service';
import { NetService } from './../../services/net-service';
import { ReinforcementService } from './../../services/reinforcement-service';
import { ToastService } from 'services/toast-service';
import { ErrorService } from 'services/error-service';
import { NettingService } from 'services/netting-service';
import { NettingTypeService } from 'services/netting-type-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, observable } from 'aurelia-framework';
import { Models } from 'models/core';
import { DialogService } from 'aurelia-dialog';
import { I18N } from 'aurelia-i18n';
import { Utility } from '../../utility';
import { ValidationController } from 'aurelia-validation';
import { Prompt } from 'elements/prompt';
import { AuthorizeStep } from 'authorizeStep';
import { SimpleOfferTabBase } from './simple-offer-tab-base';
import { NetOfferService } from 'services/net-offer-service';

@autoinject
export class SimpleOfferTabNetting extends SimpleOfferTabBase {
  private netId: number;
  private netObject: any;
  private nextTabIndex: number = null;
  private formIsValid = true;
  private locked = false;
  private nettings: Array<Models.Netting>;
  private nettingNewFormVisible = false;
  private nettingEditFormVisible: number = null;
  private reinforcementNewFormVisible = false;
  private reinforcementEditFormVisible: number = null;

  private changeTabSubscription: Subscription;
  private subscription1: Subscription;
  private subscription2: Subscription;
  private subscription4: Subscription;
  private subscription5: Subscription;

  private isAdmin: boolean;
  private nettingTypes: Array<Models.NettingType>;
  private reinforcements: Array<Models.Reinforcement>;
  private originalObject;
  private validationMessages: Array<string>;

  constructor(
    private dialogService: DialogService,
    private eventAggregator: EventAggregator,
    private netDimensionService: NetDimensionService,
    private i18n: I18N,
    private nettingService: NettingService,
    private netService: NetService,
    private reinforcementService: ReinforcementService,
    private nettingTypeService: NettingTypeService,
    private toastService: ToastService,
    private utility: Utility,
    private validationController: ValidationController,
    errorService: ErrorService,
    netOfferService: NetOfferService
  ) {
    super(netOfferService, errorService);
  }

  async activate(params: { NetId: number; Id: number }) {
    this.isAdmin = AuthorizeStep.auth.roles && AuthorizeStep.auth.roles.indexOf('Administrator') !== -1;

    this.netId = params.NetId;
    await this.setOfferIsOrder(params.Id);

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.changeTabSubscription = this.eventAggregator.subscribe('changeTab', (tabIndex: number) => {
      this.nextTabIndex = tabIndex;
    });

    // Update nettings array when creating new nettings
    this.subscription1 = this.eventAggregator.subscribe('nettingListReset', async () => {
      this.nettingEditFormVisible = null;
      await this.getNettings();
      await this.getReinforcements();
      this.eventAggregator.publish('offer-refresh', 'price');
    });

    this.subscription2 = this.eventAggregator.subscribe('nettingFormEditClose', () => {
      return this.getNettings();
    });

    this.eventAggregator.subscribe('nettingFormNewClose', () => {
      this.nettingNewFormVisible = false;
    });

    this.subscription4 = this.eventAggregator.subscribe('reinforcementListReset', () => {
      this.reinforcementEditFormVisible = null;
      this.getReinforcements();
      this.eventAggregator.publish('offer-refresh', 'price');
    });

    this.subscription5 = this.eventAggregator.subscribe('reinforcementFormEditClose', () => {
      this.getReinforcements();
    });

    this.eventAggregator.subscribe('reinforcementFormNewClose', () => {
      this.reinforcementNewFormVisible = false;
    });

    this.getReinforcements();
    this.getNettings();
    this.getNet();
  }

  private getNet() {
    this.netService
      .get(this.netId + '?$expand=NetDimension')
      .then((res) => {
        this.getNettings();

        this.netObject = res;

        this.updateOriginalObject();
        this.locked = res.Locked || this.netIsLinkedToAnalysis(res.NetDimension) || this.offerIsOrder ? true : false;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  public detached() {
    if (this.changeTabSubscription) {
      this.changeTabSubscription.dispose();
    }
  }

  public deactivate() {
    if (this.changeTabSubscription) {
      this.changeTabSubscription.dispose();
    }

    if (this.subscription4) {
      this.subscription4.dispose();
    }
    if (this.subscription5) {
      this.subscription5.dispose();
    }
  }

  private addValidationRules() {
    /*     ValidationRules.ensure("NetTypeId")
      .required()
      .withMessage(this.i18n.tr("general.requiredField"))
      .on(this.net); */
  }

  private validateForm() {
    this.validationController.validate().then((result) => {
      if (result.valid) {
        this.formIsValid = true;
      } else {
        this.formIsValid = false;
      }
    });
  }

  private async validateAsync(): Promise<any> {
    return new Promise<void>(async (resolve, reject) => {
      this.validationController
        .validate()
        .then((result) => {
          if (result.valid) {
            this.formIsValid = true;
            resolve();
          } else {
            this.formIsValid = false;
            reject();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private updateOriginalObject() {
    if (!this.netObject) {
      return;
    }
    this.originalObject = JSON.parse(JSON.stringify(this.netObject));
  }

  private async save(): Promise<any> {
    return new Promise<void>(async (resolve, reject) => {
      this.netDimensionService
        .put(this.netObject.NetDimension, this.netObject.NetDimensionId)
        .then((res) => {
          this.toastService.showSuccess('netting.updated');
          this.updateOriginalObject();
          resolve();
        })
        .catch((err) => this.errorService.handleError(err));
    });
  }
  private async changeTab(direction = 'next') {
    const messages = await this.validateNettings();

    if (messages.length > 0) {
      this.toastService.showWarning('general.checkValidationErrors');
      return;
    }

    if (direction === 'prev') {
      this.eventAggregator.publish('triggerPrevTab', true);
    } else {
      this.eventAggregator.publish('triggerNextTab', true);
    }
  }

  saveAndChangeTab(direction = 'next') {
    // does it validate ?
    this.validateAsync()
      .then(() => {
        // check if different
        if (!this.utility.areEqual(this.netObject, this.originalObject, true)) {
          // save
          this.save().then((res) => {
            this.changeTab(direction);
          });
        } else {
          this.changeTab(direction);
        }
      })
      .catch((error) => {
        this.toastService.showWarning('general.checkValidationErrors');
      });
  }

  canDeactivate() {
    this.validateForm();
    if (!this.formIsValid) {
      this.toastService.showWarning('general.checkValidationErrors');
      return false;
    }

    if (
      this.originalObject &&
      !this.locked &&
      (!this.utility.areEqual(this.netObject, this.originalObject, true) ||
        this.reinforcementNewFormVisible ||
        this.reinforcementEditFormVisible > 0 ||
        this.nettingNewFormVisible ||
        this.nettingEditFormVisible > 0)
    ) {
      // tslint:disable-next-line:no-console
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: {
            header: 'dialog.pleaseConfirmHeader',
            message: 'dialog.unsavedChangesText',
          },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            const result = response.output;
            if (result === 'save') {
              // save the nettingtype and let that function handle the rest of the logic
              void this.save();
              return false;
            } else {
              this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
              return true;
            }
          }
        });
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }

  private async getReinforcements() {
    try {
      const reinforcements = await this.reinforcementService.getAll(
        '?$filter=NetId eq ' + this.netId + '&$expand=ReinforcementType,ReinforcementMounting,NettingType'
      );
      this.reinforcements = reinforcements;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  async deleteReinforcement(id: number) {
    try {
      await this.reinforcementService.delete(id);
      this.toastService.showSuccess('reinforcement.deleted');
      void this.getReinforcements();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async getNettings() {
    await this.validateNettings();

    this.nettingService
      .getAll(
        '?$expand=NettingType,MeshSize,Placement,NylonType,Material,ThreadType,NettingColor&$filter=NetId eq ' +
          this.netId
      )
      .then((res) => {
        this.nettings = res;
        if (this.nettings.length === 0) {
          this.nettingNewFormVisible = true;
        }
      })
      .catch((err) => this.errorService.handleError(err));
  }

  async onSameNettingOnWholeNetChange(hasSame: boolean) {
    this.netObject.NetDimension.HasSameNettingOnAllNet = hasSame;
    await this.save();
    await this.validateNettings();
  }

  async onIsCombiNetChange(isCombiNet: boolean){
    this.netObject.NetDimension.IsCombiNet = isCombiNet;
    await this.validateNettings();
    await this.save();
  }

  public async deleteNetting(id: number) {
    try {
      await this.nettingService.delete(id);
      this.toastService.showSuccess('netting.deleted');
      await this.getNettings();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async validateNettings() {
    const messages = await this.nettingService.validateNettings(this.netId);

    this.validationMessages = messages;
    return messages;
  }

  public editReinforcement(reinforcement) {
    this.reinforcementEditFormVisible = reinforcement.Id;
    if (reinforcement.ReinforcementTypeId == 1) {
      // main rope
      if (reinforcement.MeterAboveRope == null) {
        reinforcement.MeterAboveRope = 0.5;
      }
      if (reinforcement.MeterBelowRope == null) {
        reinforcement.MeterBelowRope = 0.5;
      }
      if (reinforcement.ReinforcementMountingId == null) {
        // default Outside of net, under rope
        reinforcement.ReinforcementMountingId = 1;
      }

      if (reinforcement.NettingTypeId == null) {
        if (this.netObject?.NetDimension?.IsCombiNet) {
          // get netting from top part / big fish
          const netting = this.nettings.find((x) => x.PlacementId == 9);
          reinforcement.NettingTypeId = netting?.NettingTypeId;
        }

        if (reinforcement.NettingTypeId == null) {
          // get netting from sides
          const netting = this.nettings.find((x) => x.PlacementId == 1);
          reinforcement.NettingTypeId = netting?.NettingTypeId;
        }

        if (reinforcement.NettingTypeId == null) {
          // get netting from "all net"
          const netting = this.nettings.find((x) => x.PlacementId == 4);
          reinforcement.NettingTypeId = netting?.NettingTypeId;
        }
      }
    } else if (reinforcement.ReinforcementTypeId == 2) {
      // bottom rope
      if (reinforcement.MeterAboveRope == null) {
        reinforcement.MeterAboveRope = 0.5;
      }
      if (reinforcement.MeterBelowRope == null) {
        reinforcement.MeterBelowRope = 0.5;
      }
      if (reinforcement.ReinforcementMountingId == null) {
        // default Outside of net, under rope
        reinforcement.ReinforcementMountingId = 1;
      }

      if (reinforcement.NettingTypeId == null) {
        if (this.netObject?.NetDimension?.IsCombiNet) {
          // get netting from bottom part / small fish
          const netting = this.nettings.find((x) => x.PlacementId == 8);
          reinforcement.NettingTypeId = netting?.NettingTypeId;
        }

        if (reinforcement.NettingTypeId == null) {
          // get netting from sides
          const netting = this.nettings.find((x) => x.PlacementId == 1);
          reinforcement.NettingTypeId = netting?.NettingTypeId;
        }

        if (reinforcement.NettingTypeId == null) {
          // get netting from "all net"
          const netting = this.nettings.find((x) => x.PlacementId == 4);
          reinforcement.NettingTypeId = netting?.NettingTypeId;
        }
      }
    } else if (reinforcement.ReinforcementTypeId == 3) {
      // center bottom
      if (reinforcement.MeterFromCenter == null) {
        reinforcement.MeterFromCenter = 5;
      }
      if (reinforcement.ReinforcementMountingId == null) {
        // default Inside of net, permanent
        reinforcement.ReinforcementMountingId = 3;
      }

      if (reinforcement.NettingTypeId == null) {
        if (this.netObject?.NetDimension?.IsCombiNet) {
          // get netting from bottom part / small fish
          const netting = this.nettings.find((x) => x.PlacementId == 8);
          reinforcement.NettingTypeId = netting?.NettingTypeId;
        }

        if (reinforcement.NettingTypeId == null) {
          // get netting from bottom
          const netting = this.nettings.find((x) => x.PlacementId == 2);
          reinforcement.NettingTypeId = netting?.NettingTypeId;
        }

        if (reinforcement.NettingTypeId == null) {
          // get netting from "all net"
          const netting = this.nettings.find((x) => x.PlacementId == 4);
          reinforcement.NettingTypeId = netting?.NettingTypeId;
        }
      }
    }
  }
}
